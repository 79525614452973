#home {
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none
        }
    }
}

.app__header {
    flex: 1;
    height: 100%;
    width: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;
}

.app__header {
    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    
    @media screen and (max-width: 1200px) {
        padding-top: 8rem;
        flex-direction: column;
    }

    @media screen and (max-width: 400px) {
        padding-top: 6em 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    z-index: 1;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    

    

    .badge-cmp, .tag-cmp {
        padding: 1rem 1rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    
}

.app__header-start {
    display: flex;
    flex-direction: column;

    font-size: 500%;

    .app__header-name {
        display: flex;
        flex-direction: row;

        

        p {
            padding-top: 5rem;
            padding-right: 1.5rem;
            padding-left: 1rem;

            @media screen and (max-width: 1500px) {
                padding-top: 2rem;  
            }
        }

        h1 {
            color: var(--secondary-color);
        }

        @media screen and (max-width: 1500px) {
            font-size: 50%;
        }
    }
}

.dev-text {
    font-size: 27%;

    span {
        font-size: 105%;
        font-weight: bolder;

        transition: all 0.2s ease-in-out;

        &:hover {
            // color: #6bdaee;
            color: var(--secondary-color);
            font-size: 110%;
            
        }
    }
}

.app__header-myname {
    // text-shadow:0 10px 0 #6bdaee
    span {
        transition: all 0.15s ease-in-out;
        // &:hover {
        //     color: #38b6ff98;
            
        //     // color: var(--secondary-color);
            
            
        // }
    }
}
